import Swal from 'sweetalert2';

export class PosSwalHelper {

  static forPosSuccess(message: string, timer: number = 2000) {
    Swal.fire({
      position: 'bottom',
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      grow: 'row',
      showConfirmButton: false,
      background: '#FFE3DA',
      customClass: {
        popup: 'custom-popup',
      },
      timer: timer,
      html: `
      <style>
      div:where(.swal2-container) .swal2-html-container {
        padding: 0em 0em !important;
        font-family: Roboto Condensed;
      }
      .custom-popup {
        justify-content: center;
        overflow: auto;
        color: #2C2C2C;
        background-color: #C8E6C9 !important;
        font-size: 0.1em;
        border-radius: 8px !important;
        padding: 12em 0em !important;
        max-width: 375px;
      }
      .custom-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 0.7rem;
      }
      .custom-icon img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      @media (max-width: 768px) {
        .custom-popup {
          width: 100%;
        }
      }
      </style>
      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="custom-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
          fill="currentColor" class="bi bi-check-circle"
          viewBox="0 0 16 16" style="color:green;">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
          </svg>
        </div>
        <div class="text-left">
          <p class="mb-0">Congratulations!</p>
          <p class="font-weight-bold" style="margin: 0; font-size:12px">${message}</p>
        </div>
      </div>
      `,
    });
  }

  static forPosFailure(message: string, timer: number = 2000) {
    Swal.fire({
      position: 'bottom',
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      grow: 'row',
      showConfirmButton: false,
      showCloseButton: true,
      background: '#FFE3DA',
      customClass: {
        popup: 'custom-popup',
      },
      timer: timer,
      html: `
      <style>
      div:where(.swal2-container) .swal2-html-container {
        padding: 0em 0em !important;
        font-family: Roboto Condensed;
      }
      .custom-popup {
        justify-content: center;
        overflow: auto;
        color: #E82635;
        background-color: #FFEAEB !important;
        font-size: 0.1em;
        border-radius: 4px !important;
        padding: 12em 0em !important;
        max-width: 375px;
      }
      .swal2-close {
        font-size: 1.5em;
        color: #FFEAEB;
        top: 5px;
        right: 5px;
      }

      @media (max-width: 768px) {
        .custom-popup {
          width: 100%;
        }
      }
      </style>
      <div class="text-left ml-3">
        <p class="mb-0" style="color:#E82635 !important">Error!</p>
        <p class="font-weight-bold" style="margin:0;font-size:12px;color:#E82635 !important">${message}</p>
      </div>
      `,
    });
  }

  static forPosWarning(message: string, timer: number = 2000) {
    Swal.fire({
      position: 'bottom',
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      grow: 'row',
      showConfirmButton: false,
      showCloseButton: true,
      background: '#FFF3CD',
      customClass: {
        popup: 'custom-popup-warning',
      },
      timer: timer,
      html: `
      <style>
      div:where(.swal2-container) .swal2-html-container {
        padding: 0em 0em !important;
        font-family: Roboto Condensed;
      }
      .custom-popup-warning {
        justify-content: center;
        overflow: auto;
        color: #856404;
        background-color: #FFF3CD !important;
        font-size: 0.1em;
        border-radius: 4px !important;
        padding: 12em 0em !important;
        max-width: 375px;
      }
      .swal2-close {
        font-size: 1.5em;
        color: #856404;
        top: 5px;
        right: 5px;
      }

      @media (max-width: 768px) {
        .custom-popup-warning {
          width: 100%;
        }
      }
      </style>
      <div class="text-left ml-3">
        <p class="mb-0">Warning!</p>
        <p class="font-weight-bold" style="margin:0;font-size:12px;color:#856404 !important">${message}</p>
      </div>
      `,
    });
  }

  static forPosSuccessWithIcon(message: any, timer: number = 2000) {
    const swalContent = `
      <style>
  .btn-primary{
  color:#fff !important";
  border-radius:2px
  }

.custom-title{
color:#000 !important;
font-size:1.25rem !important;
margin-bottom:0px !important
}

.swal2-close {
    background: transparent !important; /* Remove background */
    color: rgba(255, 60, 0, 0.966) !important; /* Change color to orange */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    cursor: pointer; /* Change cursor to pointer */
    transition: transform 0.2s ease; /* Add transition for animation */
}

.swal2-close:focus {
    outline: none !important; /* Ensure no outline on focus */
    box-shadow: none !important; /* Remove any focus shadow */
}

/* Hover effect with animation */
.swal2-close:hover {
    transform: scale(1.1); /* Scale up on hover */
    color: rgb(255, 68, 0) !important; /* Change color on hover for effect */
}
</style>
      <button type="button" id="button-close" class="btn btn-primary btn-sm py-2 px-3 mt-3" style="color:#fff" (click)="Swal.close()" data-toggle="modal" data-target="#p">Okay</button>
    `;

    Swal.fire({
      icon: 'success',
      title: `<h5 class="custom-title font-weight-bold">${message}</h5>`,
      html: swalContent,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        confirmButton: 'custom-swal-button',
      },
      didOpen: () => {
        const button = document.getElementById('button-close');
        if (button != null) {
          button.addEventListener('click', () => {
            Swal.close();
          });
        }
      },
    });
  }


  static forPosWarningWithIcon(message: any, timer: number = 2000) {
    const swalContent = `
      <style>
  .btn-primary{
  color:#fff !important";
  border-radius:2px
  }

.custom-title{
color:#000 !important;
font-size:1.25rem !important;
margin-bottom:0px !important
}

.swal2-close {
    background: transparent !important; /* Remove background */
    color: rgba(255, 60, 0, 0.966) !important; /* Change color to orange */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    cursor: pointer; /* Change cursor to pointer */
    transition: transform 0.2s ease; /* Add transition for animation */
}

.swal2-close:focus {
    outline: none !important; /* Ensure no outline on focus */
    box-shadow: none !important; /* Remove any focus shadow */
}

/* Hover effect with animation */
.swal2-close:hover {
    transform: scale(1.1); /* Scale up on hover */
    color: rgb(255, 68, 0) !important; /* Change color on hover for effect */
}
</style>
      <button type="button" id="button-close" class="btn btn-primary btn-sm py-2 px-3 mt-3" style="color:#fff" (click)="Swal.close()" data-toggle="modal" data-target="#p">Okay</button>
    `;

    Swal.fire({
      icon: 'warning',
      title: `<h5 class="custom-title font-weight-bold">${message}</h5>`,
      html: swalContent,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        confirmButton: 'custom-swal-button',
      },
      didOpen: () => {
        const button = document.getElementById('button-close');
        if (button != null) {
          button.addEventListener('click', () => {
            Swal.close();
          });
        }
      },
    });
  }


  static forPosFailureWithIcon(message: any, timer: number = 2000) {
    const swalContent = `
      <style>
  .btn-primary{
  color:#fff !important";
  border-radius:2px
  }

.custom-title{
color:#000 !important;
font-size:1.25rem !important;
margin-bottom:0px !important
}

.swal2-close {
    background: transparent !important; /* Remove background */
    color: rgba(255, 60, 0, 0.966) !important; /* Change color to orange */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    cursor: pointer; /* Change cursor to pointer */
    transition: transform 0.2s ease; /* Add transition for animation */
}

.swal2-close:focus {
    outline: none !important; /* Ensure no outline on focus */
    box-shadow: none !important; /* Remove any focus shadow */
}

/* Hover effect with animation */
.swal2-close:hover {
    transform: scale(1.1); /* Scale up on hover */
    color: rgb(255, 68, 0) !important; /* Change color on hover for effect */
}
</style>
      <button type="button" id="button-close" class="btn btn-primary btn-sm py-2 px-3 mt-3" style="color:#fff" (click)="Swal.close()" data-toggle="modal" data-target="#p">Okay</button>
    `;

    Swal.fire({
      icon: 'error',
      title: `<h5 class="custom-title font-weight-bold">${message}</h5>`,
      html: swalContent,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        confirmButton: 'custom-swal-button',
      },
      didOpen: () => {
        const button = document.getElementById('button-close');
        if (button != null) {
          button.addEventListener('click', () => {
            Swal.close();
          });
        }
      },
    });
  }
}
